$font-family-base: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, "Lucida Grande", Arial, sans-serif;

@import "normalize.scss";
// @import "bourbon.scss";

@import "bootstrap/variables";
@import "bootstrap/mixins";
@import "bootstrap/scaffolding";
@import "bootstrap/grid";
@import "bootstrap/responsive-utilities";
@import "bootstrap/type";

// $fa-font-path: "/dist/fonts";
@import "font-awesome.scss";


.fb-box {
	border: 1px solid;
	border-color: #e5e6e9 #dfe0e4 #d0d1d5;
	border-radius: 3px;
	margin-bottom: 10px;
	background: #fff;
}

